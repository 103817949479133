<script setup>
import { computed, onMounted, ref, toRef } from 'vue';

const props = defineProps({
  modelValue: [String, Number],
  placeholder: String,
  type: {
    type: String,
    default: 'text'
  },
  labelWidth: String,
  fieldStyle: String,
  disabled: Boolean,
  max: [String, Number],
  min: [String, Number],
  // To allow for decimal numbers use step="any"
  step: {
    type: [String, Number],
    default: null
  },
  suffix: String
});

const emit = defineEmits(['update:modelValue', 'keyup', 'keydown', 'blur']);

const input = ref(null);


let labelWidth = computed(() => {
    const labelWidth = props.labelWidth; 
    if(labelWidth == 'sm'){
        return "w-28"; 
    }
    if(labelWidth == 'xs')
        return 'w-20';

    return "w-28 md:w-52"; 
});

let inputPaddingLeft = computed(() => {
    const labelWidth = props.labelWidth; 
    if(labelWidth == 'sm'){
        return "pl-36"; 
    }
    if(labelWidth == 'xs')
        return 'pl-28';
        
    return "pl-36 md:pl-48";  
});

if(props.type == 'number'){
    if(props.step == 1){
        emit('update:modelValue', parseInt(props.modelValue));
    }else{
        emit('update:modelValue', parseFloat(props.modelValue).toFixed(2));
    }
}

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});


let style = props.fieldStyle;
</script>

<template>
        <div :class="`relative ${props.disabled && 'opacity-50 cursor-not-allowed'}`">
            <label :class="`${labelWidth} ${props.disabled && 'opacity-50 cursor-not-allowed'} absolute text-xs ml-3 mt-1.5 uppercase t-1 font-bold text-slate-400 truncate  pr-2 `" >{{placeholder}}</label>
            <label class="absolute opacity-50 font-bold right-0 top-1/2 transform -translate-y-1/2 mr-3" v-if="props.suffix">{{props.suffix}}</label>
            <input @blur="e => emit('blur', e)" @keydown="e => emit('keydown', e)" @keyup="e => emit('keyup', e)" :disabled="props.disabled" :max="props.max" :min="props.min"  :step="step || 1" :class="`border border-gray-200 bg-white pt-5 pl-3 pb-2  rounded-md shadow-sm w-full ${props.disabled && 'cursor-not-allowed'}`" :type="type" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" ref="input"/>
        </div>
</template>
