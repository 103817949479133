

<script setup>
const props = defineProps({
    type: {
        type: String,
        default: 'submit',
    },
    form: { type: Object, default: null }, 
    btnStyle: {type: String, default: 'default' },
    class: {type: String, default: 'text-sm md:text-md py-1 px-2 md:py-2 md:px-4'},
    disabled: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
});

import Spinner from './Spinner.vue'

const classes = function(){

    switch(props.btnStyle) {
        case 'danger':
            return " inline-flex items-center  disabled:text-red-200 font-bold border-2 bg-red-400  disabled:border-red-200 disabled:cursor-not-allowed border border-transparent rounded-md font-semibold text-sm text-white tracking-widest hover:border-red-600  focus:outline-none  transition ease-in-out duration-150"
            break;
        case 'success':
            return " inline-flex disabled:cursor-not-allowed disabled:opacity-50 items-center border-2  bg-green-500 font-bold border-green-400 border border-transparent rounded-md font-semibold text-sm text-white tracking-widest hover:border-green-500  focus:outline-none  transition ease-in-out duration-150"
            break;  
        case 'disabled':
            return " inline-flex items-center  bg-gray-400 border border-transparent rounded-md font-semibold text-sm text-white tracking-widest hover:bg-gray-500 active:bg-gray-200 focus:outline-none focus:border-gray-900 focus:shadow-outline-gray transition ease-in-out duration-150"
            break;
        case 'secondary': 
            return " inline-flex disabled:text-slate-300 disabled:border-slate-200 disabled:cursor-not-allowed items-center  bg-white border border-blue rounded-md font-bold text-sm text-blue tracking-widest hover:bg-gray-100 active:bg-purple-200 focus:outline-none focus:border-gray-900 focus:shadow-outline-gray transition ease-in-out duration-150"
            break; 
        case 'secondary2': 
            return " inline-flex disabled:text-slate-300 disabled:border-slate-200 disabled:cursor-not-allowed items-center  bg-white border border-slate-400 rounded-md font-bold text-sm text-slate-500 tracking-widest hover:bg-gray-100 focus:outline-none focus:border-gray-900 focus:shadow-outline-gray transition ease-in-out duration-150"
            break; 
        default:
           return " inline-flex disabled:cursor-not-allowed disabled:opacity-50 disabled:text-slate-300 items-center  bg-gradient-to-tr from-blue to-purple-500 border hover:to-purple-300 transition-all border-transparent rounded-md font-semibold text-sm text-white tracking-widest hover:bg-purple-300 active:bg-purple-200 focus:outline-none focus:border-gray-900 focus:shadow-outline-gray transition ease-in-out duration-150"
    }
}
</script>

<template>
    <button :type="type"  :class="classes() + ' ' + props.class" :disabled="form ? (form.processing || disabled) : ( disabled || loading )">
        <span v-if="(form && form.processing) || loading ">
            <Spinner class="w-4 mr-2"></Spinner>
        </span>
        <slot />
        <span v-if="form && form.id && !$slots.default">Update</span>
        <span v-if="form && !form.id  && !$slots.default">Create</span>
    </button>
</template>
